import * as styles from './TableOfContents.module.scss'

import React from 'react'
import { compact } from 'lodash'
import classNames from 'classnames'

interface Props {
  tableOfContents: readonly ({
    name: string | null
    slug: string | null
    readonly children:
      | readonly ({
          name: string | null
          slug: string | null
        } | null)[]
      | null
  } | null)[]
  large?: boolean | null
}

const TableOfContents: React.FC<Props> = (props) => {
  const { tableOfContents, large } = props

  return (
    <section
      className={classNames(styles.container, {
        [styles.large]: large,
      })}
    >
      <h2 className={styles.heading}>Table of Contents</h2>
      <ul className={styles.sections}>
        {compact(tableOfContents).map((tocItem) => {
          return (
            <li key={tocItem.slug}>
              <a className={styles.link} href={`#${tocItem.slug}`}>
                {tocItem.name}
              </a>
              {tocItem.children && (
                <ul className={styles.sectionsChild}>
                  {compact(tocItem.children).map((childItem) => (
                    <li key={childItem.slug}>
                      <a className={styles.link} href={`#${childItem.slug}`}>
                        {childItem.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default TableOfContents
